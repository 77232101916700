export default [
  {
    title: 'Пользователи',
    route: 'users-table',
    icon: 'UsersIcon'
  },
  {
    title: 'Рекламодатели',
    route: 'advertisers',
    icon: 'PackageIcon'
  },
  {
    title: 'Кампании',
    route: 'advertising-campaigns',
    icon: 'BoxIcon'
  },
  {
    title: 'Платформы',
    route: 'platforms',
    icon: 'CastIcon'
  },
  {
    title: 'Статистика (Паблишеры)',
    route: 'publisher-statistic',
    icon: 'PieChartIcon'
  },
  {
    title: 'Статистика DSP',
    route: 'dsp-statistic',
    icon: 'BarChartIcon'
  },
  {
    title: 'Статистика РК',
    route: 'campaign-statistic',
    icon: 'BarChart2Icon'
  },
  {
    title: 'Финансы',
    route: 'finance',
    icon: 'DollarSignIcon'
  },
  {
    title: 'Логи',
    route: 'logs',
    icon: 'FileTextIcon'
  }
]
